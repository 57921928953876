import React from 'react';
import './App.css';

function App() {
  return (
      <div className="App">
        <div className="centered-content">
          <div className="emoji" role="img" aria-label="Invader Emoji">👾</div>
          <div>
            <div className="title"><a href="https://invaders.social">Social Invaders</a></div>
            <div className="text">Coming soon! Stay tuned...</div>
          </div>
            <div className="footer">
                <span>🚀</span>&nbsp;<span class="text-powered">Powered </span><span className="text-by">by</span> <a href="https://tamia.team" title="Tamia Team" class="text-tamia-team">Tamia Team</a>
            </div>
            <a href="https://tamia.team">
            <img className="team-tamia-logo" src="/logo-tamia-256.png" width="64" height="64" alt="Tamia Team" />
            </a>
        </div>
      </div>
  );
}

export default App;
